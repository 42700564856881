// i18n.js
'use strict';

import { createI18nInstance as createI18nServerInstance } from '../resource/i18nServer.js';
import { createI18nInstance as createI18nClientInstance } from '../resource/i18nClient.js';
import { getIsInNode } from '../resource/getJsEnvironment.js';
import retryWithBackoff from '../resource/retryWithBackoff.js';

export {
  LANGUAGE_QUERY_NAME,
  DEFAULT_USED_I18N_KEYS,
  localeConvertor,
  languageCodeValidator,
} from '../resource/i18nCommon.js';

const isServer = getIsInNode();

/** @type {import('i18next').i18n} */
const globalI18nInstance = isServer
  ? createI18nServerInstance()
  : createI18nClientInstance();

/**
 * @param {import('i18next').InitOptions} config
 * @returns {Promise<{ i18n: import('i18next').i18n, initI18nPromise: Promise }>}
 */
const getI18nServerInstance = config => {
  const instance = globalI18nInstance;
  let initI18nPromise;

  if (!instance.isInitialized) {
    initI18nPromise = retryWithBackoff({
      promiseFunction: () =>
        new Promise((resolve, reject) => {
          instance.init(config, error => {
            // Reject only when all languages are failed to fetch
            if (error?.length === globalI18nInstance.languages?.length)
              reject(error);
            else resolve();
          });
        }),
      getNextTimeToWait: () => 5000,
      maxRetries: 5,
    });
  } else {
    initI18nPromise = Promise.resolve(globalI18nInstance.t);
  }

  return { i18n: instance, initI18nPromise };
};

/**
 * @param {import('i18next').InitOptions} config
 * @returns {Promise<{ i18n: import('i18next').i18n}>}
 */
const getI18nClientInstance = config => {
  const instance = globalI18nInstance;

  if (!instance.isInitialized) {
    instance.init(config);
  }

  return { i18n: instance };
};

/**
 * try to init i18n and return i18n instance
 * @param {import('i18next').InitOptions} config
 * @returns {Promise<{ i18n: import('i18next').i18n, initI18nPromise: Promise }>}
 */
export const getI18nInstance = isServer
  ? getI18nServerInstance
  : getI18nClientInstance;

// the i18n instance, might not init yet
export default globalI18nInstance;
